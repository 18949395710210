var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.impr.ibmStepCd !== "IS00000001" &&
                _vm.impr.suppleCheckFlag !== "Y",
              expression:
                "impr.ibmStepCd !== 'IS00000001' && impr.suppleCheckFlag !== 'Y'",
            },
          ],
          staticClass: "col-12",
        },
        [
          _c("c-step", {
            attrs: {
              stepperGrpCd: "IBM_STEP_CD",
              currentStepCd: _vm.impr.ibmStepCd,
              stepRejectItems: _vm.stepRejectItems,
              disabled: true,
            },
            model: {
              value: _vm.impr.ibmStepCd,
              callback: function ($$v) {
                _vm.$set(_vm.impr, "ibmStepCd", $$v)
              },
              expression: "impr.ibmStepCd",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.suppleFlag,
              expression: "suppleFlag",
            },
          ],
          staticClass: "col-12",
        },
        [
          _c("c-step", {
            attrs: {
              stepperGrpCd: "IBM_SUPPLE_STEP_CD",
              currentStepCd: _vm.impr.ibmSuppleStepCd,
              disabled: true,
            },
            model: {
              value: _vm.impr.ibmSuppleStepCd,
              callback: function ($$v) {
                _vm.$set(_vm.impr, "ibmSuppleStepCd", $$v)
              },
              expression: "impr.ibmSuppleStepCd",
            },
          }),
        ],
        1
      ),
      _vm.overFlag && this.impr.approvalStatusCd !== "ASC9999999"
        ? _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "q-banner",
                {
                  staticClass: "text-white bg-red",
                  attrs: { dense: "", "inline-actions": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "avatar",
                        fn: function () {
                          return [
                            _c("q-icon", {
                              attrs: {
                                name: "report_problem",
                                color: "grey-3",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    407695491
                  ),
                },
                [_c("b", { staticClass: "text-h5" }, [_vm._v("지연")])]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.impr.suppleCheckFlag === "Y",
              expression: "impr.suppleCheckFlag === 'Y'",
            },
          ],
          class:
            _vm.impr.saveSuppleFlag === "Y"
              ? "col-xs-12 col-sm-12 col-md-12 col-lg-6"
              : "col-12",
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "q-form",
                  { ref: "editSuppleForm" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "보완 요청 상세" },
                      },
                      [
                        _c(
                          "template",
                          { slot: "card-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _c("c-btn", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.suppleRequestEditable,
                                      expression: "suppleRequestEditable",
                                    },
                                  ],
                                  attrs: {
                                    isSubmit: _vm.isSaveSupple,
                                    url: _vm.saveUrl,
                                    param: _vm.impr,
                                    mappingType: "PUT",
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveSupple,
                                    btnCallback: _vm.saveSuppleCallback,
                                  },
                                }),
                                _c("c-btn", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.suppleRequestEditable,
                                      expression: "suppleRequestEditable",
                                    },
                                  ],
                                  attrs: {
                                    isSubmit: _vm.isSaveSuppleRequest,
                                    url: _vm.saveUrl,
                                    param: _vm.impr,
                                    mappingType: "PUT",
                                    label: "보완요청",
                                    icon: "send",
                                    color: "red",
                                  },
                                  on: {
                                    beforeAction: _vm.saveSuppleRequest,
                                    btnCallback: _vm.saveSuppleRequestCallback,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "card-description" },
                          [
                            !_vm.impr.saveSuppleFlag
                              ? _c(
                                  "font",
                                  {
                                    staticClass: "text-negative",
                                    staticStyle: {
                                      "font-size": "0.8em",
                                      "font-weight": "300",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " (※ 적합 / 보완 처리가 되지 않았습니다.) "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-6 col-md-6 col-lg-4",
                            },
                            [
                              _c("c-select", {
                                attrs: {
                                  editable: _vm.suppleEditable,
                                  comboItems: _vm.suppleFlagItems,
                                  itemText: "codeName",
                                  itemValue: "code",
                                  label: "적합/보완 여부",
                                  name: "suppleFlag",
                                },
                                on: { datachange: _vm.supplechange },
                                model: {
                                  value: _vm.impr.suppleFlag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.impr, "suppleFlag", $$v)
                                  },
                                  expression: "impr.suppleFlag",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.impr.saveSuppleFlag === "Y"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-6 col-md-6 col-lg-4",
                                },
                                [
                                  _c("c-datepicker", {
                                    attrs: {
                                      editable: _vm.suppleRequestEditable,
                                      required: true,
                                      label: "보완완료 요청일",
                                      name: "suppleScheduleDate",
                                    },
                                    model: {
                                      value: _vm.impr.suppleScheduleDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.impr,
                                          "suppleScheduleDate",
                                          $$v
                                        )
                                      },
                                      expression: "impr.suppleScheduleDate",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.impr.saveSuppleFlag === "Y"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                                },
                                [
                                  _c("c-textarea", {
                                    attrs: {
                                      editable: _vm.suppleRequestEditable,
                                      required: true,
                                      label: "보완요청내용",
                                      name: "suppleRequest",
                                    },
                                    model: {
                                      value: _vm.impr.suppleRequest,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.impr, "suppleRequest", $$v)
                                      },
                                      expression: "impr.suppleRequest",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.impr.saveSuppleFlag === "Y",
                                  expression: "impr.saveSuppleFlag === 'Y'",
                                },
                              ],
                              staticClass: "col-12",
                            },
                            [
                              _c("c-upload", {
                                ref: "suppleAttach",
                                attrs: {
                                  attachInfo: _vm.attachSupple,
                                  editable: _vm.suppleAttachEditable,
                                  label: "보완 관련 첨부파일",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.impr.suppleCheckFlag === "Y" &&
                _vm.impr.saveSuppleFlag === "Y",
              expression:
                "impr.suppleCheckFlag === 'Y' && impr.saveSuppleFlag === 'Y'",
            },
          ],
          staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6",
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "q-form",
                  { ref: "editSuppleActionReviewForm" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "보완 조치&검토 상세" },
                      },
                      [
                        _c(
                          "template",
                          { slot: "card-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _c("c-btn", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.suppleReviewEditable,
                                      expression: "suppleReviewEditable",
                                    },
                                  ],
                                  attrs: {
                                    isSubmit: _vm.isReturnSupple,
                                    url: _vm.saveUrl,
                                    param: _vm.impr,
                                    mappingType: "PUT",
                                    label: "반려",
                                    icon: "front_hand",
                                    color: "red-3",
                                  },
                                  on: {
                                    beforeAction: _vm.returnSupple,
                                    btnCallback: _vm.returnSuppleCallback,
                                  },
                                }),
                                _c("c-btn", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.suppleActionReviewBtnEditable,
                                      expression:
                                        "suppleActionReviewBtnEditable",
                                    },
                                  ],
                                  attrs: {
                                    isSubmit: _vm.isSaveSuppleActionReview,
                                    url: _vm.saveUrl,
                                    param: _vm.impr,
                                    mappingType: "PUT",
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveSuppleActionReview,
                                    btnCallback:
                                      _vm.saveSuppleActionReviewCallback,
                                  },
                                }),
                                _c("c-btn", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.suppleReviewEditable,
                                      expression: "suppleReviewEditable",
                                    },
                                  ],
                                  attrs: {
                                    isSubmit: _vm.isSaveSuppleComplete,
                                    url: _vm.saveUrl,
                                    param: _vm.impr,
                                    mappingType: "PUT",
                                    label: "보완완료",
                                    icon: "directions_run",
                                    color: "red",
                                  },
                                  on: {
                                    beforeAction: _vm.saveSuppleComplete,
                                    btnCallback: _vm.saveSuppleCompleteCallback,
                                  },
                                }),
                                _c("c-btn", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.suppleActionEditable,
                                      expression: "suppleActionEditable",
                                    },
                                  ],
                                  attrs: {
                                    isSubmit: _vm.isSaveSuppleReview,
                                    url: _vm.saveUrl,
                                    param: _vm.impr,
                                    mappingType: "PUT",
                                    label: "검토요청",
                                    icon: "send",
                                    color: "red",
                                  },
                                  on: {
                                    beforeAction: _vm.saveSuppleReview,
                                    btnCallback: _vm.saveSuppleReviewCallback,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-6 col-md-6 col-lg-4",
                            },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  editable: _vm.suppleActionEditable,
                                  required: true,
                                  label: "보완완료일",
                                  name: "suppleCompleteDate",
                                },
                                model: {
                                  value: _vm.impr.suppleCompleteDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.impr,
                                      "suppleCompleteDate",
                                      $$v
                                    )
                                  },
                                  expression: "impr.suppleCompleteDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                            },
                            [
                              _c("c-textarea", {
                                attrs: {
                                  editable: _vm.suppleActionEditable,
                                  required: true,
                                  rows: 4,
                                  label: "보완처리내용",
                                  name: "suppleActionContents",
                                },
                                model: {
                                  value: _vm.impr.suppleActionContents,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.impr,
                                      "suppleActionContents",
                                      $$v
                                    )
                                  },
                                  expression: "impr.suppleActionContents",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                            },
                            [
                              _c("c-textarea", {
                                attrs: {
                                  editable: _vm.suppleReviewEditable,
                                  rows: 4,
                                  label: "보완검토내용",
                                  name: "suppleReviewContents",
                                },
                                model: {
                                  value: _vm.impr.suppleReviewContents,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.impr,
                                      "suppleReviewContents",
                                      $$v
                                    )
                                  },
                                  expression: "impr.suppleReviewContents",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "q-form",
                { ref: "editForm1" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "요청&접수 상세" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c(_vm.relationTableInfo.component, {
                                key: _vm.relationTableInfo.key,
                                tag: "component",
                                attrs: {
                                  taskParam: _vm.relationTableInfo.taskParam,
                                  disabled: !Boolean(
                                    _vm.popupParam.sopImprovementId
                                  ),
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.requestFinishBtnEditable,
                                    expression: "requestFinishBtnEditable",
                                  },
                                ],
                                attrs: {
                                  label: "종료",
                                  icon: "pan_tool",
                                  color: "red-custom",
                                },
                                on: { btnClicked: _vm.finishPop },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.requestAreaEditable &&
                                      _vm.popupParam.sopImprovementId,
                                    expression:
                                      "requestAreaEditable&&popupParam.sopImprovementId",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isDelete,
                                  url: _vm.deleteUrl,
                                  param: _vm.impr,
                                  mappingType: "DELETE",
                                  label: "삭제",
                                  icon: "remove",
                                },
                                on: {
                                  beforeAction: _vm.removeImpr,
                                  btnCallback: _vm.removeCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.saveBtnEditable1,
                                    expression: "saveBtnEditable1",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isSaveRequestReceipt,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveRequestReceiptImpr,
                                  btnCallback: _vm.saveRequestReceiptCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.requestBtnEditable,
                                    expression: "requestBtnEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isRequest,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "조치부서에 요청",
                                  icon: "send",
                                  color: "red",
                                },
                                on: {
                                  beforeAction: _vm.requestImpr,
                                  btnCallback: _vm.requestCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.receiptBtnEditable,
                                    expression: "receiptBtnEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isReceipt,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "접수",
                                  icon: "directions_run",
                                  color: "red",
                                },
                                on: {
                                  beforeAction: _vm.receiptImpr,
                                  btnCallback: _vm.receiptCallback,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-3",
                          },
                          [
                            _c("c-select", {
                              attrs: {
                                disabled: true,
                                editable: _vm.editable,
                                comboItems: _vm.taskComboItems,
                                type: "edit",
                                itemText: "codeName",
                                itemValue: "code",
                                name: "ibmTaskTypeCd",
                                label: "업무",
                              },
                              model: {
                                value: _vm.impr.ibmTaskTypeCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "ibmTaskTypeCd", $$v)
                                },
                                expression: "impr.ibmTaskTypeCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-3",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                disabled: true,
                                editable: _vm.editable,
                                name: "sopImprovementId",
                                label: "개선번호",
                              },
                              model: {
                                value: _vm.impr.sopImprovementId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "sopImprovementId", $$v)
                                },
                                expression: "impr.sopImprovementId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: true,
                                label: "요청부서/이름/요청일",
                                name: "request",
                                value: _vm.request,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.requestAreaEditable,
                                required: true,
                                label: "제목",
                                name: "ibmTitle",
                              },
                              model: {
                                value: _vm.impr.ibmTitle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "ibmTitle", $$v)
                                },
                                expression: "impr.ibmTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.requestAreaEditable,
                                required: true,
                                label: "요청내용",
                                name: "improveRequestContents",
                              },
                              model: {
                                value: _vm.impr.improveRequestContents,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.impr,
                                    "improveRequestContents",
                                    $$v
                                  )
                                },
                                expression: "impr.improveRequestContents",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          },
                          [
                            _c("c-construnction", {
                              attrs: {
                                editable: _vm.requestAreaEditable,
                                name: "sopConstructionId",
                              },
                              model: {
                                value: _vm.impr.sopConstructionId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "sopConstructionId", $$v)
                                },
                                expression: "impr.sopConstructionId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-8" },
                                [
                                  _c("c-plant", {
                                    attrs: {
                                      editable: _vm.requestAreaEditable,
                                      required: true,
                                      type: "edit",
                                      name: "plantCd",
                                    },
                                    model: {
                                      value: _vm.impr.plantCd,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.impr, "plantCd", $$v)
                                      },
                                      expression: "impr.plantCd",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-checkbox", {
                                    attrs: {
                                      editable: false,
                                      isFlag: true,
                                      trueLabel: "업체",
                                      falseLabel: "부서",
                                      trueValue: "V",
                                      falseValue: "D",
                                      label: "",
                                      name: "actionFlag",
                                    },
                                    model: {
                                      value: _vm.impr.actionFlag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.impr, "actionFlag", $$v)
                                      },
                                      expression: "impr.actionFlag",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-vendor", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.impr.actionFlag === "V",
                                  expression: "impr.actionFlag === 'V'",
                                },
                              ],
                              attrs: {
                                editable: false,
                                type: "edit",
                                label: "조치업체",
                                name: "actionVendorCd",
                              },
                              model: {
                                value: _vm.impr.actionVendorCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "actionVendorCd", $$v)
                                },
                                expression: "impr.actionVendorCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                editable: _vm.requestAreaEditable,
                                required: true,
                                label: "조치완료 요청일",
                                name: "actionCompleteRequestDate",
                              },
                              model: {
                                value: _vm.impr.actionCompleteRequestDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.impr,
                                    "actionCompleteRequestDate",
                                    $$v
                                  )
                                },
                                expression: "impr.actionCompleteRequestDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-field", {
                              attrs: {
                                editable:
                                  _vm.requestAreaEditable ||
                                  _vm.actionReceiptAreaEditable,
                                type: "dept_user",
                                data: _vm.impr,
                                deptValue: "actionCompleteCheckDeptCd",
                                label: "조치완료 확인자",
                                name: "actionCompleteCheckUserId",
                              },
                              model: {
                                value: _vm.impr.actionCompleteCheckUserId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.impr,
                                    "actionCompleteCheckUserId",
                                    $$v
                                  )
                                },
                                expression: "impr.actionCompleteCheckUserId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.actionReceiptAreaEditable,
                                label: "요청취소 사유",
                                name: "requestRejectReason",
                              },
                              model: {
                                value: _vm.impr.requestRejectReason,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "requestRejectReason", $$v)
                                },
                                expression: "impr.requestRejectReason",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.impr.ibmStepCd === "IS00000025"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                              },
                              [
                                _c("c-textarea", {
                                  attrs: {
                                    disabled: "",
                                    editable: _vm.editable,
                                    label: "종료 사유",
                                    name: "finishRemark",
                                  },
                                  model: {
                                    value: _vm.impr.finishRemark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.impr, "finishRemark", $$v)
                                    },
                                    expression: "impr.finishRemark",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-upload", {
                              attrs: {
                                attachInfo: _vm.attachBeforeInfo,
                                editable:
                                  _vm.requestAreaEditable ||
                                  _vm.actionReceiptAreaEditable ||
                                  _vm.actionAreaEditable,
                                label: "개선 전 사진",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.overFlag,
                  expression: "overFlag",
                },
              ],
              staticClass: "col-12",
            },
            [
              _c(
                "q-form",
                { ref: "overForm" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "지연상세" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.overEditable,
                                    expression: "overEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isOver,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveOverImpr,
                                  btnCallback: _vm.saveOverCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.overEditable,
                                    expression: "overEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isOverComplete,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "목표일 설정 완료",
                                  icon: "check",
                                },
                                on: {
                                  beforeAction: _vm.approvalOverImpr,
                                  btnCallback: _vm.approvalOverImrpCallback,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                editable: _vm.overEditable,
                                required: true,
                                start: _vm.$comm.getToday(),
                                label: "목표일",
                                name: "targetDate",
                              },
                              model: {
                                value: _vm.impr.targetDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "targetDate", $$v)
                                },
                                expression: "impr.targetDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.overEditable,
                                required: true,
                                label: "지연사유",
                                name: "delayReason",
                              },
                              model: {
                                value: _vm.impr.delayReason,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "delayReason", $$v)
                                },
                                expression: "impr.delayReason",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "q-form",
                { ref: "editForm2" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "조치상세" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.saveBtnEditable2,
                                    expression: "saveBtnEditable2",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isSaveAction,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveActionImpr,
                                  btnCallback: _vm.saveActionCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.actionCompleteBtnEditable,
                                    expression: "actionCompleteBtnEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isActionComplete,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "조치완료",
                                  icon: "directions_run",
                                  color: "red",
                                },
                                on: {
                                  beforeAction: _vm.actionCompleteImpr,
                                  btnCallback: _vm.actionCompleteCallback,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                editable: _vm.actionAreaEditable,
                                required: true,
                                label: "조치완료일",
                                name: "actionCompleteDate",
                              },
                              model: {
                                value: _vm.impr.actionCompleteDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "actionCompleteDate", $$v)
                                },
                                expression: "impr.actionCompleteDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                editable: _vm.actionAreaEditable,
                                required: true,
                                label: "검토완료 요청일",
                                name: "reviewCompleteRequestDate",
                              },
                              model: {
                                value: _vm.impr.reviewCompleteRequestDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.impr,
                                    "reviewCompleteRequestDate",
                                    $$v
                                  )
                                },
                                expression: "impr.reviewCompleteRequestDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.actionAreaEditable,
                                required: true,
                                label: "조치내용",
                                name: "actionContents",
                              },
                              model: {
                                value: _vm.impr.actionContents,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "actionContents", $$v)
                                },
                                expression: "impr.actionContents",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-upload", {
                              attrs: {
                                attachInfo: _vm.attachAfterInfo,
                                editable: _vm.actionAreaEditable,
                                label: "개선 후 사진",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "q-form",
                { ref: "editForm3" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "검토상세" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.rejectBtnEditable,
                                    expression: "rejectBtnEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isReturn,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "반려",
                                  icon: "front_hand",
                                  color: "red-3",
                                },
                                on: {
                                  beforeAction: _vm.returnImpr,
                                  btnCallback: _vm.returnCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.saveBtnEditable3,
                                    expression: "saveBtnEditable3",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isSaveCheck,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveCheckImpr,
                                  btnCallback: _vm.saveCheckCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.completeBtnEditable,
                                    expression: "completeBtnEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isComplete,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "개선완료",
                                  icon: "check_circle_outline",
                                  color: "red",
                                },
                                on: {
                                  beforeAction: _vm.completeImpr,
                                  btnCallback: _vm.completeCallback,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.requestCheckAreaEditable,
                                required: true,
                                label: "검토내용",
                                name: "reviewContents",
                              },
                              model: {
                                value: _vm.impr.reviewContents,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "reviewContents", $$v)
                                },
                                expression: "impr.reviewContents",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "q-dialog",
        {
          ref: "deptLogDialog",
          model: {
            value: _vm.isOpenLog,
            callback: function ($$v) {
              _vm.isOpenLog = $$v
            },
            expression: "isOpenLog",
          },
        },
        [
          _c(
            "q-card",
            {
              staticClass: "dept-card",
              staticStyle: { width: "500px", "max-width": "500px !important" },
            },
            [
              _c(
                "q-card-section",
                {
                  staticClass:
                    "row items-center q-pb-none px-2 py-1 bg-orange-custom text-white",
                },
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v("조치부서 변경이력"),
                  ]),
                  _c("q-space"),
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { flat: "", round: "", dense: "", icon: "close" },
                  }),
                ],
                1
              ),
              _c("q-separator"),
              _c(
                "q-card-section",
                { staticClass: "dept-content p-2" },
                [
                  _c(
                    "q-list",
                    { attrs: { bordered: "", separator: "" } },
                    _vm._l(_vm.impr.logs, function (log, logIdx) {
                      return _c(
                        "q-item",
                        { key: logIdx },
                        [
                          _c(
                            "q-item-section",
                            { attrs: { avatar: "" } },
                            [_c("q-icon", { attrs: { name: "info_outline" } })],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _vm._v(
                                  _vm._s(
                                    `${log.beforeDeptName} → ${log.afterDeptName}`
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            { attrs: { side: "", top: "" } },
                            [
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(
                                  _vm._s(`변경자 : ${log.changeUserName}`)
                                ),
                              ]),
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(_vm._s(`변경일 : ${log.changeDt}`)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "q-dialog",
        {
          ref: "finishDialog",
          attrs: { persistent: "" },
          model: {
            value: _vm.isOpenFinish,
            callback: function ($$v) {
              _vm.isOpenFinish = $$v
            },
            expression: "isOpenFinish",
          },
        },
        [
          _c(
            "q-card",
            {
              staticClass: "finish-impr-card",
              staticStyle: { width: "500px", "max-width": "500px !important" },
            },
            [
              _c(
                "q-card-section",
                {
                  staticClass:
                    "row items-center q-pb-none px-2 py-1 bg-orange-custom text-white",
                },
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v("개선 종료 사유"),
                  ]),
                  _c("q-space"),
                  _vm.requestFinishBtnEditable
                    ? _c("q-btn", {
                        attrs: { icon: "done", flat: "", round: "", dense: "" },
                        on: { click: _vm.finishImpr },
                      })
                    : _vm._e(),
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { flat: "", round: "", dense: "", icon: "close" },
                  }),
                ],
                1
              ),
              _c("q-separator"),
              _c(
                "q-card-section",
                { staticClass: "dept-content p-2" },
                [
                  _c("c-textarea", {
                    attrs: {
                      rows: 5,
                      editable: _vm.editable,
                      disabled: !_vm.requestFinishBtnEditable,
                    },
                    model: {
                      value: _vm.impr.finishRemark,
                      callback: function ($$v) {
                        _vm.$set(_vm.impr, "finishRemark", $$v)
                      },
                      expression: "impr.finishRemark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }